<template>
    <div class='abilityDetails'>
        <div class="content">
            <!-- 时间 -->
            <div class="time top">
              <svg-icon icon-class="back" class="arr arr-left" @click="getDate(false)"/>
              <div class="current">{{ current }}</div>
              <svg-icon v-show="current != today" icon-class="back" class="arr arr-right"  @click="getDate(true)"/>
            </div>
            <!-- 计时 -->
            <div class="timing" v-if="totalSleep.length">
                <div class="num">{{ totalSleep[0] }}</div>
                <div>小时</div>
                <div class="num">{{ totalSleep[1] }}</div>
                <div>分钟</div>
            </div>
            <!-- tips -->
            <div class="sleepTips">
                <div class="deep">深睡</div>
                <div class="shallow">浅睡</div>
                <div class="clear">清醒</div>
            </div>
            <!-- 折线图 -->
            
            <div id="BrokenLine" class="histogram"></div>
            <!-- 饼状图 -->
            <div class="pieChart">
                <div id="Cake" class="cakeItem"></div>
                <div class="tips">
                    <div class="tipsItem" v-for="(item, index) in tipsData" :key="index">
                        <div class="left">
                            <div class="tipicon" :style="{'backgroundColor': item.bagcolor}"></div>
                            <div>{{item.title}}</div>
                        </div>
                        <div class="right">
                            <div class="num">{{item.hour}}</div>
                            <div>小时</div>
                            <div class="num">{{item.minute}}</div>
                            <div>分钟</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="content">
            <!-- 标题 -->
            <div class="time">
                <div class="current">睡眠记录</div>
            </div>
            <!-- 折线图 -->
            <div class="echart">
                <div id="HistoryBrokenLine" class="histogram"></div>
            </div>
            <HistoryBar @onIndexCahnge="onIndexCahnge"></HistoryBar>
            <div class="sleepTips">
                <div class="deep">{{ historySleepDeepPCT }}</div>
                <div class="shallow">{{ historySleepTanPCT }}</div>
                <div class="clear">{{ historySleepSoberPCT }}</div>
            </div>

        </div>

    </div>
</template>

<script>
import { current, ChangeHourMinutestr } from '@/util/validate'
import * as echarts from 'echarts'
import {
  TitleComponent,
  TooltipComponent,
  GridComponent,
  VisualMapComponent
} from 'echarts/components'
import { LineChart } from 'echarts/charts'
import { UniversalTransition } from 'echarts/features'
import { CanvasRenderer } from 'echarts/renderers'
import { GetWatchTypeByDate, GetWatchTypeByHistoryDate } from '@/api/index'
import { mapGetters } from 'vuex'
import HistoryBar from '../../components/tabbar/HistoryBar.vue'

echarts.use([
  TitleComponent,
  TooltipComponent,
  GridComponent,
  VisualMapComponent,
  LineChart,
  CanvasRenderer,
  UniversalTransition
])

export default {
  components: {
    HistoryBar
  },
  data () {
    return {
      current: current(),
      today: current(),
      totalSleep: [],
      tipsData: [],
      pieData: [], // 饼状图,
      xData: [],
      yData: [],
      currentActiveIndex:0,
      historyXData:[],//历史时间
      historyYData:[],//历史睡眠
      historySleepDeepPCT:'',//历史深睡占比
      historySleepTanPCT:'',//历史浅睡占比
      historySleepSoberPCT:'',//历史清醒占比
    }
  },
  props: {

  },
  created () {

  },
  mounted () {
    this.getSleepData()
    this.getHistorySleepData()
  },
  methods: {
    //获取睡眠数据-根据日期
    getSleepData(){
        GetWatchTypeByDate({
            userId: 'Mini_44c7f5ff-52a0-48c6-8c64-592c9f6fc5e9',
            time: this.current,
            type: 4// 查询类型 1心率 2血压 3血氧 4睡眠 5压力 6步数
        }).then(res => {
            const _data = res.data
            const sd = ChangeHourMinutestr(_data.SleepDeepString)
            const ss = ChangeHourMinutestr(_data.SleepSleepString)
            const st = ChangeHourMinutestr(_data.SleepTanString)
            this.totalSleep = ChangeHourMinutestr(_data.SleepCount)
            this.pieData = [
                { value: _data.SleepDeepPCT, itemStyle: { color: '#D330FF' } },
                { value: _data.SleepTanPCT, itemStyle: { color: '#E6B3F5' } },
                { value: _data.SleepSoberPCT, itemStyle: { color: '#FCB334' } }
            ]
            this.tipsData = [
                {
                title: '深睡',
                bagcolor: '#9A18E8',
                hour: sd[0],
                minute: sd[1]
                },
                {
                title: '浅睡',
                bagcolor: '#E6B3F5',
                hour: st[0],
                minute: st[1]
                },
                {
                title: '清醒',
                bagcolor: '#FCB334',
                hour: ss[0],
                minute: ss[1]
                }
            ]

            const arry = []; const arrx = []
            _data.SleepDeepList.forEach(v => {
                const x = v.BloodSleepDateTime.split(' ')[1]
                let y = 0
                if (v.BloodSleepDeep == 1) {
                y = 3
                } else if (v.BloodSleepTan == 1) {
                y = 2
                } else {
                y = 1
                }
                arry.push(y)
                arrx.push(x)
            })
            this.xData = arrx
            this.yData = arry
            this.$nextTick(() => {
                this.initializationBrokenLine()
                this.initializationCake()
            })
        }).catch(err=>{
          this.totalSleep = []
          this.tipsData = []
          this.pieData = []
          this.xData = []
          this.yData = []
          this.$nextTick(() => {
            this.initializationBrokenLine()
            this.initializationCake()
          })
        })
    },
    // 初始化折线图
    initializationBrokenLine () {
      if (!document.getElementById('BrokenLine')) {
        return
      }
      var chartDom = document.getElementById('BrokenLine')
      var myChart = echarts.init(chartDom)
      var option
      option = {
        visualMap: {
          type: 'piecewise',
          show: false,
          dimension: 1, // 1 -根据y数据,0- 根据x 数据
          seriesIndex: 0, // 第一部分数据
          pieces: [{
            lte: 1,
            color: '#FCB334'
          }, {
            lte: 2,
            color: '#E6B3F5'
          }, {
            lte: 3,
            color: '#9A18E8'
          }]
        },

        // tooltip: {
        //     trigger: 'axis',
        // },
        xAxis: {
          // type: 'category',
          // boundaryGap: false,
          data: this.xData,
          axisTick: { show: false },
          axisLine: { show: false },
          axisLabel: {
            interval: this.xData.length - 2
            // formatter: function(){
            //     return "";
            // }
          }
        },
        yAxis: {
          type: 'value',
          show: false,
          axisLabel: {
            formatter: function () {
              return ''
            }
          },
        //   splitNumber:3
        },

        series: [
          {
            type: 'line',
            showSymbol: false,
            data: this.yData,
            smooth: 0.6,
            lineStyle: {
              width: 3
            }
          }
        ]
      }

      option && myChart.setOption(option)
    },
    // 初始化饼状图
    initializationCake () {
      if (!document.getElementById('Cake')) {
        return
      }
      var chartDom = document.getElementById('Cake')
      var myChart = echarts.init(chartDom)
      var option

      option = {
        tooltip: {
          trigger: 'item',
          show: false
        },
        series: [
          {
            type: 'pie',
            radius: ['40%', '70%'],
            avoidLabelOverlap: false,
            itemStyle: {
              borderRadius: 2,
              borderColor: '#fff',
              borderWidth: 2,
              normal: {
                color: function (colors) {
                  return colors.color
                }
              }
            },
            legend: {
              selectedMode: false
            },
            label: {
              show: false,
              position: 'center'
            },
            emphasis: {
              label: {
                show: true,
                fontSize: 40,
                fontWeight: 'bold'
              }
            },
            labelLine: {
              show: false
            },
            data: this.pieData
          }
        ]
      }
      option && myChart.setOption(option)
    },

    //获取历史心率数据
    getHistorySleepData(){
        GetWatchTypeByHistoryDate({
            userId: 'Mini_44c7f5ff-52a0-48c6-8c64-592c9f6fc5e9',
            timeType: this.currentActiveIndex+1,//1近一周 2近一个月 3近三个月 4近半年 5近一年
            dateTtpe: 4// 查询类型 1心率 2血压 3血氧 4睡眠 5压力 6步数
        }).then(res => {
            const _data = res.data
            console.log('------',_data)
            let _list = _data.sleepDeepList.reverse()
            this.historyXData = _list.map(item=>{
                return item.time;
            })
            this.historyYData = _list.map(item=>{
                return item.value;
            })
            this.historySleepDeepPCT = _data.sleepDeepPCT
            this.historySleepSoberPCT = _data.sleepSoberPCT
            this.historySleepTanPCT = _data.sleepTanPCT
            this.$nextTick(() => {
                this.initHistoryBrokenLine()
            })
        })
    },
    initHistoryBrokenLine(){
      if (!document.getElementById('HistoryBrokenLine')) {
        return
      }
      let chartDom = document.getElementById('HistoryBrokenLine')
      let myChart = echarts.init(chartDom)
      let option
      option = {
        tooltip: {
            trigger: 'axis',
        },
        xAxis: {
          type: 'category',
          data: this.historyXData,
        //   nameLocation: "end",
          boundaryGap: false,
          axisLine: {
            show: false // 是否显示坐标轴
          },
          axisTick: {
            show: false // 是否显示坐标轴刻度
          },
          splitLine: { show: false },// 去除网格线
          axisLabel: {
            showMinLabel: true,
            showMaxLabel: true,
            margin:'20',
            formatter:(v, i)=> {
              return this.currentActiveIndex == 0 || this.currentActiveIndex == 1? v.substring(5):v
            },
          }
        },
        yAxis: {
          type: 'value',
          scale: true,
          // max : 100,
          min: 0,
          splitNumber: 3,
        },
        grid: {
          left: "10%",
          right: "7%",
          bottom:"20%"
        },
        series: [
          {
            type: 'line',
            symbol: 'none',
            sampling: 'lttb',
            itemStyle: {
              color: '#FF9281'
            },
            areaStyle: {
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                {
                  offset: 0,
                  color: '#FF9180'
                },
                {
                  offset: 1,
                  color: '#FFC9C0'
                }
              ])
            },
            data: this.historyYData
          }
        ]
      }
      option && myChart.setOption(option)
    },
    onIndexCahnge(index){
      this.currentActiveIndex = index
      this.getHistorySleepData()
    },
    getDate(flag){
      let t = flag? new Date(this.current).getTime()+86400000: new Date(this.current).getTime()-86400000
      this.current = current(new Date(t))
      this.getSleepData()
    },
  },
  watch: {

  },
  computed: {
    ...mapGetters([
      'userid'
    ])
  }
}
</script>
<style lang='scss' scoped>
.abilityDetails {
    height: 100%;
    padding: 0 20px;
    background: url('https://gotocloud8-open-sx.oss-cn-hangzhou.aliyuncs.com/www/HealthingCore/modularBag.png') no-repeat;
    background-size: 100%;
    background-color: #f7f7f7;
    .content {
        width: 100%;
        border-radius: 15px;
        background: #fff;
        // height: 80%;
        padding: 20px;
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        margin-bottom: 20px;
        .time {
            color: #868686;
            display: flex;
            align-items: center;
            font-size: 16px;
            position: relative;
            .arr-left{
              position: absolute;
              top:50%;
              transform: translate(0, -50%);
              left:-10px;
            }
            .arr-right{
              position: absolute;
              top:50%;
              transform: translate(0, -50%) rotate(180deg);
              right:-10px;
            }
            &.top{
              margin-bottom: 20px;
              display: flex;
              align-content: center;
            }
            .current {
                font-size: 20px;
                font-weight: 700;
                margin: 0 10px;
            }
        }

        .timing {
            display: flex;
            align-items: center;
            color: #868686;
            font-size: 20px;
            margin: 20px 0;
            .num {
                color: #4F4F4F;
                font-size: 30px;
            }
        }
        .sleepTips {
            width: 100%;
            display: flex;
            justify-content: space-around;
            color: #808080;
            .deep,
            .shallow,
            .clear {
                position: relative;
            }
            .deep::before,
            .shallow::before,
            .clear::before{
                content: "";
                width: 10px;
                height: 10px;
                position: absolute;
                left: -15px;
                top: 50%;
                transform: translateY(-50%);
                border-radius: 5px;
            }
            .deep::before {
                background: #9A18E8;
            }
            .shallow::before {
                background: #E6B3F5;
            }
            .clear::before {
                background: #FCB334;
            }
        }
        .echart {
            width: 100%;
            position: relative;
        }
        .histogram {
            width: 100%;
            height: 240px;
            box-sizing: border-box;
            &.p-20{
                padding:0 20px;
            }
            // /deep/ canvas {
            //     width: 110% !important;
            //     height: 100% !important;
            //     left: -5% !important;
            // }
        }
        .pieChart {
            width: 100%;
            display: flex;
            .cakeItem {
                min-width: 120px;
                height: 120px;
                /deep/ canvas {
                    width: 120% !important;
                    height: 120% !important;
                    left: -10% !important;
                    top: -10% !important;
                }
            }
            .tips {
                color: #808080;
                width: 100%;
                display: flex;
                flex-direction: column;
                justify-content: space-around;
                .tipsItem {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    margin-left: 20px;
                    .left {
                        display: flex;
                        align-items: center;
                        font-size: 14px;
                        .tipicon {
                            width: 5px;
                            height: 15px;
                            border-radius: 2px;
                            margin-right: 10px;
                        }
                    }
                    .right {
                        display: flex;
                        align-items: center;
                        .num {
                            color:#282828;
                            font-size: 24px;
                        }
                    }
                }
            }
        }
    }
}
</style>
